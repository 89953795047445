/* jjpk-me/node
 *
 * Copyright (C) 2020 - Julien JPK
 * This file is part of the frontend code for my personal website at jjpk.me.
 *
 * There is very little value in granting permissions over such a specific code
 * base. It is therefore published with no license. All rights reserved. */

import '@fortawesome/fontawesome-free';

import "./link_confirm";
import "./forms";
import "./markdown";
import "./pastes";
