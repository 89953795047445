/* jjpk-me/node
 *
 * Copyright (C) 2020 - Julien JPK
 * This file is part of the frontend code for my personal website at jjpk.me.
 *
 * There is very little value in granting permissions over such a specific code
 * base. It is therefore published with no license. All rights reserved. */

import EasyMDE from 'easymde';

document.addEventListener("DOMContentLoaded", function(event) {
    let editors = document.getElementsByClassName('markdown-editor');
    Array.from(editors).forEach(e => {
        new EasyMDE({
            element: e,
            autoDownloadFontAwesome: false,
            spellChecker: false,
            indentWithTabs: false
        });
    });
});
