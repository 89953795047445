/* jjpk-me/node
 *
 * Copyright (C) 2020 - Julien JPK
 * This file is part of the frontend code for my personal website at jjpk.me.
 *
 * There is very little value in granting permissions over such a specific code
 * base. It is therefore published with no license. All rights reserved. */

import {
    randomBytes,
    createCipheriv,
    createDecipheriv,
    createHmac
} from "crypto";
import { scrypt } from "scrypt-js";
import autosize from "autosize";
import ClipboardJS from "clipboard";

const getPasteEditForm = () => document.getElementById('paste_edit_form');
const getPasteTypeSelect = () => document.getElementById('paste_type');
const getPasteCiphertextInput = () => document.getElementById('paste_ciphertext');
const getPasteContentElement = () => document.getElementById('paste_content');
const getPastePasswordModal = () => document.getElementById('paste_password_modal');
const getPastePasswordInput = () => document.getElementById('paste_password_input');
const getPastePasswordButton = () => document.getElementById('paste_password_button');
const getPasteSaveButton = () => document.getElementById('paste_save_button');
const getPasteModalError = () => document.getElementById('paste_modal_error');


/* Client-side encryption functions */

function buildCipher(key, iv, decipher) {
    const cipher_direction = decipher ? createDecipheriv : createCipheriv;
    let cipher = cipher_direction("aes-256-ctr", key, iv);
    if(!decipher) cipher.setEncoding("hex");
    return cipher;
}

function sign(cleartext, key) {
    let hmac = createHmac("sha512", key);
    hmac.update(cleartext);
    return hmac.digest("hex");
}

function local_scrypt(pw_buffer, salt) {
    return scrypt(pw_buffer, salt, 16384, 8, 1, 256 / 8);
}

function cipher(callback, error_callback) {
    const password = getPastePasswordInput().value;
    const cleartext = getPasteContentElement().value;
    const ciphertext_input = getPasteCiphertextInput();

    randomBytes(128 / 8, (err, salt) => {
        if (err) return error_callback(err);
        const pw_buffer = Buffer.from(password, "utf-8");
        local_scrypt(pw_buffer, salt).then((key) => {
            randomBytes(16, (err, iv) => {
                if(err) return error_callback(err);
                const cipher = buildCipher(key, iv, false);

                let ciphertext = "";
                cipher.on("data", (chunk) => ciphertext += chunk);
                cipher.write(cleartext);
                cipher.end();

                ciphertext_input.value =
                    salt.toString("hex") + ":" +
                    iv.toString("hex") + ":" +
                    ciphertext + ":" +
                    sign(cleartext, key);

                callback();
            });
        });
    });
}

function decipher(callback, error_callback) {
    const cleartext_element = getPasteContentElement();
    const ciphertext_input = getPasteCiphertextInput();
    const cipher_final = ciphertext_input.value;
    const cipher_parts = cipher_final.split(":");
    if (cipher_parts.length !== 4 || cipher_parts.some((v) => v.length < 1)) {
        return error_callback("invalid ciphertext format, ciphering appears to have failed when the paste was created");
    }

    const salt = Buffer.from(cipher_parts[0], "hex");
    const iv = Buffer.from(cipher_parts[1], "hex");
    const ciphertext = Buffer.from(cipher_parts[2], "hex");
    const expected_signature = cipher_parts[3];
    const pw_buffer = Buffer.from(getPastePasswordInput().value, "utf-8");

    local_scrypt(pw_buffer, salt).then((key) => {
        const decipher = buildCipher(key, iv, true);
        let cleartext = "";
        decipher.on("data", (chunk) => cleartext += chunk);
        decipher.write(ciphertext);
        decipher.end();

        const signature = sign(cleartext, key);
        if (signature !== expected_signature) {
            return error_callback("password is incorrect");
        }

        cleartext = decodeURIComponent(escape(cleartext))
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");

        if (cleartext_element.tagName === "textarea") {
            cleartext_element.value = cleartext;
        } else {
            cleartext_element.innerHTML = cleartext;
        }

        callback();
    });
}


/* Modal utils */

function togglePasswordModal(show) {
    const password_modal = getPastePasswordModal();
    const password_input = getPastePasswordInput();
    password_input.value = "";

    if (show) {
        password_modal.classList.add('is-active');
        password_input.focus();
    } else {
        password_modal.classList.remove('is-active');
    }
}

function togglePasswordModalButton(active) {
    const password_button = getPastePasswordButton();
    if (active) {
        password_button.innerHTML = "Proceed";
        password_button.removeAttribute("disabled");
    } else {
        password_button.innerHTML = "...";
        password_button.setAttribute("disabled", "disabled");
    }
}

function setModalError(message) {
    getPasteModalError().innerHTML = message === null ? "" : message;
}


/* Password input modal logic */

document.addEventListener("DOMContentLoaded", function(event) {
    const password_input = getPastePasswordInput();
    if (password_input === null) return;

    password_input.addEventListener('keypress', e => {
        if (e.key !== "Enter") return;
        e.preventDefault();
        getPastePasswordButton().click();
    });
});


/* Deciphering logic */

document.addEventListener("DOMContentLoaded", function(event) {
    const cleartext_element = getPasteContentElement();
    const ciphertext_input = getPasteCiphertextInput();

    if (cleartext_element === null || ciphertext_input === null || ciphertext_input.value.length < 1) {
        return;
    }

    togglePasswordModal(true);
    const password_button = getPastePasswordButton();
    password_button.addEventListener('click', e => {
        if (password_button.type === "submit") return;

        togglePasswordModalButton(false);
        setModalError(null);
        decipher(() => {
            togglePasswordModal(false);
            password_button.type = "submit";
            togglePasswordModalButton(true);
        }, (err) => {
            setModalError("Failed to decipher: " + err);
            togglePasswordModalButton(true);
        });
    });
});


/* Logic for the paste edit form */

document.addEventListener("DOMContentLoaded", function(_) {
    const form = getPasteEditForm();
    if (form === null) return;

    /* Auto-fit the textarea */
    autosize(getPasteContentElement());

    /* Adjust the save buttons when type changes */
    const type_select = getPasteTypeSelect();
    const save_button = getPasteSaveButton();
    const password_button = getPastePasswordButton();
    type_select.addEventListener('change', e => {
        save_button.innerHTML = (e.target.value === 'ciphertext' ? "Input master password" : "Save");
        password_button.type = (e.target.value === 'ciphertext' ? "submit" : "button");
    });

    /* Capture the form submission event for ciphered pastes, insert encryption logic */
    const password_input = getPastePasswordInput();
    form.addEventListener('submit', e => {
        if (type_select.value !== 'ciphertext') return;
        e.preventDefault();

        if (password_input.value.length < 1) {
            togglePasswordModal(true);
            return;
        }

        togglePasswordModalButton(false);
        cipher(() => form.submit(), (err) => {
            setModalError("Failed to cipher: " + err);
            togglePasswordModalButton(true);
        });
    });
});


/* Logic for the paste view page */

document.addEventListener("DOMContentLoaded", function(event) {
    const content = getPasteContentElement();
    if (content === null) return;

    /* "Select all" links */
    Array.from(document.getElementsByClassName('select-all')).forEach(l => {
        l.addEventListener('click', e => {
            e.preventDefault();
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(content);
            selection.removeAllRanges();
            selection.addRange(range);
        });
    });

    /* "Copy to clipboard" links */
    const clipboard = new ClipboardJS(".copy-clipboard");
    clipboard.on("success", e => {
        e.trigger.classList.add("link-green");
        setTimeout(() => { e.trigger.classList.remove("link-green"); }, 750);
    });
    clipboard.on("error", e => {
        alert(
            "You appear to be using a rather old or distasteful web browser on which this feature does not work as it should. " +
            "The paste contents have been selected, but you will need to hit Ctrl-C to perform the actual copy yourself."
        );
    });
});
