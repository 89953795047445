/* jjpk-me/node
 *
 * Copyright (C) 2020 - Julien JPK
 * This file is part of the frontend code for my personal website at jjpk.me.
 *
 * There is very little value in granting permissions over such a specific code
 * base. It is therefore published with no license. All rights reserved. */

import flatpickr from "flatpickr";

document.addEventListener("DOMContentLoaded", function(_) {
    let inputs = document.getElementsByClassName('auto-submit');
    Array.from(inputs).forEach(i => {
        i.addEventListener('change', _ => {
            i.form.submit();
        });
    });
});

document.addEventListener("DOMContentLoaded", function(_) {
    let inputs = document.getElementsByClassName('datetime-picker');
    Array.from(inputs).forEach(i => {
        flatpickr(i, {
            enableTime: true,
            time_24hr: true,
            dateFormat: "d-m-Y H:i",
        });
    });
});
