/* jjpk-me/node
 *
 * Copyright (C) 2020 - Julien JPK
 * This file is part of the frontend code for my personal website at jjpk.me.
 *
 * There is very little value in granting permissions over such a specific code
 * base. It is therefore published with no license. All rights reserved. */

document.addEventListener("DOMContentLoaded", function(event) {
    let links = document.getElementsByClassName('link-confirm');
    Array.from(links).forEach(l => {
        l.addEventListener('click', e => {
            if(!confirm("Are you sure?")) e.preventDefault();
        });
    });
});
